<template>
  <div class="StudioListOld ">
    <div class="main_box">
      <div class="box_content">
        <div class="bread" style="
            height: 49px;
            border-bottom: 1px solid rgb(240, 240, 240);
            display: flex;
            align-items: center;
          ">
          <div class="bread_content" style="margin: 0 !important">
            <span class="span_one">当前位置：</span>
            <el-breadcrumb separator-class="el-icon-arrow-right">
              <el-breadcrumb-item to="/">
                <span class="c-b">首页</span>
              </el-breadcrumb-item>
              <el-breadcrumb-item>
                <span class="c-b">四名工作室列表</span>
              </el-breadcrumb-item>
            </el-breadcrumb>
          </div>
        </div>
      </div>
    </div>

    <!-- 筛选 -->
    <div style="background: #ebf1ff;">
      <div class="select_box">
        <div class="select_left">
          <div class="xueduan">
            <p>类型：</p>
            <div class="xueduan_block cursor" :class="hostIndex == index ? 'active_block' : ''"
              v-for="(item, index) in hostList" :key="index" @click="hostChange(index, item.id)">
              {{ item.name }}
            </div>
          </div>
          <div class="xueduan">
            <p>级别：</p>
            <div class="xueduan_block cursor" :class="citiesIndex == index ? 'active_block' : ''"
              v-for="(item, index) in citiesList" :key="index" @click="citiesChange(index, item.id)">
              {{ item.name }}
            </div>
          </div>
          <!-- //学段 -->
          <div class="xueduan">
            <p>学段：</p>
            <div class="xueduan_block cursor" :class="sectionIndex == index ? 'active_block' : ''"
              v-for="(item, index) in sectionList" :key="index" @click="sectionChange(index, item.id)">
              {{ item.name }}
            </div>
          </div>

          <!-- 学科 -->
          <div class="xueduan">
            <p>学科：</p>
            <div class="xueduan_block cursor" :class="subjectIndex == index ? 'active_block' : ''"
              v-for="(item, index) in subjectList" :key="index" @click="subjectChange(index, item.id)">
              {{ item.name }}
            </div>
          </div>

          <!-- 查找 -->
          <div class="df dfb">
            <div class="sousuo-box">
              <p>查找：</p>
              <div class="box-right">
                <div class="sousuo">
                  <el-input placeholder="请输入搜索关键词" prefix-icon="el-icon-search" v-model="form.search_name" clearable
                    @change="onChange" @keyup.enter.native="searchButton"></el-input>
                  <el-button type="primary" @click="searchButton">搜索</el-button>
                </div>
              </div>
            </div>
            <div v-if="false" class="sousuo_right pr20">
              <el-select v-model="form.all_web_id" placeholder="区域选择" clearable size="small" style="width: 100px"
                @change="searchButton">
                <el-option v-for="item in area_option" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </div>
          </div>
          <!-- end -->
        </div>
      </div>
    </div>

    <!-- //中间列表 -->
    <div class="middleBox" v-if="total > 0">
      <div class="w_layout">
        <ListTwo :list="TabData" :is_who="3" />

        <div v-show="total" class="pagina_div">
          <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
            :current-page="form.page" :page-sizes="[12, 24, 36, 48, 60]" :page-size="form.limit"
            layout="total, sizes, prev, pager, next, jumper" :total="total">
          </el-pagination>
        </div>
      </div>
    </div>
    <CommonEmpty hVal="200px" v-else />
  </div>
</template>

<script>
import ListTwo from "@/components/CommonList/ListTwo.vue";
export default {
  components: {
    ListTwo,
  },
  data() {
    return {
      //总数
      total: 0,
      form: {
        page: 1,
        limit: 12,
        search_stage_id: "",
        search_subject_id: "",
        search_host_type: "",
        search_provinces_cities_counties: "",
        search_name: "",
        provinces_cities_counties: "",
        all_web_id: 2, // 区域选择,
        is_homepage: 2, // 是否首页推荐 1是 2否,
      },
      area_option: [
        {
          value: 1,
          label: "优秀推荐",
        },
        {
          value: 2,
          label: "本区域",
        },
      ],

      //列表数据
      TabData: [],

      searchText: "", //查找文本
      sectionList: [], //学段列表
      hostList: [
        { id: '', name: "全部" },
        { id: 1, name: "名教师" },
        { id: 2, name: "名班主任" },
        { id: 3, name: "名校（园）长" },
        { id: 5, name: "名书记" },
      ], //学段列表
      citiesList: [
        { id: '', name: "全部" },
        { id: 1, name: "省级" },
        { id: 2, name: "市级" },
        { id: 3, name: "区县级" },
      ],
      sectionIndex: 0, //学段选择索引
      hostIndex: 0,
      citiesIndex: 0,

      subjectList: [{ id: "", name: "全部" }], //学科类别
      subjectIndex: 0, //学科选择索引
    };
  },
  created() {
    if (localStorage.getItem("selName")) {
      this.form.search_name = localStorage.getItem("selName")
      setTimeout(() => {
        localStorage.removeItem("selName")
      }, 1000);
    }
    this.setGrade();
    this.setStage();
    //初始化学段
    this.getSectionList();
    //初始化四名讲堂
    this.getStudioList();
  },
  methods: {
    //设置四名等级
    setGrade() {
      if (this.$route.query.grade) {
        this.sectionIndex = 0;
        this.hostIndex = 0;
        this.subjectIndex = 0;
        this.form.search_stage_id = "";
        this.form.search_subject_id = "";
        this.form.search_name = "";
        this.form.provinces_cities_counties = this.$route.query.grade;
        this.getStudioList();
      }
    },
    //设置学段默认值
    setStage() {
      if (this.$route.query.stage_id) {
        this.sectionChange(Number(this.$route.query.stage_index) + 1, this.$route.query.stage_id, "auto");
      }
    },
    //分页
    handleSizeChange(val) {
      this.$set(this.form, "page", 1);
      this.$set(this.form, "limit", val);
      this.getStudioList();
    },
    handleCurrentChange(val) {
      this.$set(this.form, "page", val);
      this.getStudioList();
    },
    // 搜索
    searchButton() {
      this.$set(this.form, "page", 1);
      this.getStudioList();
    },
    onChange(val) {
      if (!val.trim()) {
        this.searchButton();
      }
    },
    //四名讲堂列表
    getStudioList() {
      this.$axios
        .get("index/TeachingStudio/listTeachingStudio", { params: this.form })
        .then((res) => {
          this.TabData = res.data.data.data;
          this.total = res.data.data.total;
          //console.log(res.data,"数据121212");
        });
    },

    //跳转四名工作室首页
    toStudioIndex(id) {
      //将工作室id存在缓存里
      localStorage.setItem("studioId", id);
      this.$router.push({
        path: "/StudioIndex",
        //  query:{
        //    id:id
        //  }
      });
    },
    //获取学段
    async getSectionList() {
      const res = await this.$axios.get("/stage/stage_list");
      let data = res.data.data;
      data.unshift({ id: "", name: "全部" });
      this.sectionList = data;
    },

    //获取学科
    async getSubjectList(id) {
      const res = await this.$axios.get("/subject/list", {
        params: { pharseid: id },
      });
      let data = res.data.data;
      data.forEach((item) => {
        item.id = item.subjectId;
        item.name = item.subjectName;
      });
      data.unshift({ id: "", name: "全部" });
      this.subjectList = data;
    },
    //学段选择
    hostChange(index, id, type = "") {
      this.hostIndex = index;
      this.form.search_host_type = id;
      this.$set(this.form, "page", 1);
      this.getStudioList();
    },
    citiesChange(index, id, type = "") {
      this.citiesIndex = index;
      this.form.search_provinces_cities_counties = id;
      this.$set(this.form, "page", 1);
      this.getStudioList();
    },
    //学段选择
    sectionChange(index, id, type = "") {
      this.sectionIndex = index;
      if (type == "auto" && this.$route.query.subject_id) {
        this.form.provinces_cities_counties = "";
        this.subjectIndex = Number(this.$route.query.subject_index) + 1;
        this.form.search_subject_id = this.$route.query.subject_id;
      } else {
        this.subjectIndex = 0;
        this.form.search_subject_id = "";
      }
      this.form.search_stage_id = id;
      if (id) {
        this.getSubjectList(id);
      } else {
        this.subjectList = [{ id: "", name: "全部" }];
      }
      this.$set(this.form, "page", 1);
      this.getStudioList();
    },
    //学科选择
    subjectChange(index, id) {
      this.subjectIndex = index;
      this.form.search_subject_id = id;
      this.$set(this.form, "page", 1);
      this.getStudioList();
    },
  },
  watch: {
    $route: {
      handler(newVal, oldval) {
        this.setGrade();
        this.setStage();
      },
      deep: true,
    },
  },
};
</script>

<style lang="less" scoped>
.StudioListOld {
  padding-bottom: 20px;

  .main_box {
    width: 100%;
    background: #ebf1ff;

    .box_content {
      box-sizing: border-box;
      margin: 0 auto;
      width: 1400px;

      .top {
        width: 100%;
        height: 76px;
        line-height: 76px;
        border-bottom: 1px solid #f0f0f0;
        font-size: 14px;

        span:nth-of-type(2) {
          color: #ff8201;
        }
      }
    }
  }

  .select_box {
    border-radius: 6px;
    width: 1400px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
    padding-left: 20px;
    padding-bottom: 20px;

    .select_left {
      flex: 1;
      overflow: hidden;

      .xueduan {
        display: flex;
        align-items: center;
        margin-bottom: 15px;

        p {
          color: #999999;
          margin-right: 20px;
        }

        .xueduan_block {
          height: 26px;
          display: inline-flex;
          align-items: center;
          margin-right: 20px;
          padding: 0 12px;
          font-size: 16px;
          color: #333;
        }

        .xueduan_block2 {
          padding: 0px 16px;
          height: 26px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 20px;
        }

        .active_block {
          background: #3489fe;
          border-radius: 4px 3px 4px 4px;
          color: #ffffff;
        }
      }

      .sousuo-box {
        display: flex;
        align-items: center;

        p {
          color: #999999;
          flex-shrink: 0;
          margin-right: 20px;
        }

        .box-right {
          flex: 1;
          overflow: hidden;
          display: flex;
          justify-content: space-between;

          .sousuo {
            width: 320px;
            display: flex;

            /deep/ .el-input__inner {
              // border-radius: 0px;
              border-radius: 4px 0px 0px 4px;
            }

            /deep/ .el-button {
              // border-radius: 0px;
              border-radius: 0px 4px 4px 0px;
            }
          }
        }
      }
    }

    .select_right {
      .create {
        width: 140px;
        height: 38px;
        background: #ff8201;
        border-radius: 6px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #ffffff;
        font-weight: 700;
      }
    }
  }

  .middleBox {
    width: 1400px;
    margin: 0 auto;
    background: #fff;
    padding: 23px 0 0 0;
    border-radius: 6px;
    box-sizing: border-box;

    .w_layout {
      padding: 0 20px;
      box-sizing: border-box;
    }
  }
}

.sousuo_right {
  /deep/.el-input__inner {
    background-color: #f4fbff;
  }
}
</style>